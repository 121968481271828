<template>
	<div
		:class="$style.noAccess"
		v-if="!access"
	>У вас нет доступа в данный раздел
	</div>
	<div
		:class="$style.container"
		v-else-if="restaurantDetail"
	>
		<NavTabs
			:data="navItems"
			active="Ресторан"
		/>
		<InfoTable
			:textContent="restaurantTable"
			id="infoTable_restaurant"
			title="Ресторан"
			:download="false"
			@updateProperties="updateProperties($event, restaurantHeaders)"
		/>
		<div
			:class="$style.mapBlock"
			id="infoTable_map"
			@click="mapDisable = false"
		>
			<h2 :class="$style.mapBlock__title">География</h2>
			<div :class="[$style.mapBlock__body, mapDisable ? $style.mapDisable : '']">
				<Ymap
					@mapReady="map=$event"
					mapid="restMap"
				/>
			</div>
		</div>
		<InfoTable
			:textContent="detailsTable"
			id="infoTable_details"
			title="Реквизиты"
			:download="true"
			@updateProperties="updateProperties($event, detailsHeaders)"
		/>
		<InfoTable
			:textContent="contactsTable"
			id="infoTable_contacts"
			title="Контакты"
			:download="true"
			@updateProperties="updateProperties($event, contactsHeaders)"
		/>
		<InfoTable
			:column="true"
			title="Договоры"
			id="infoTable_docs"
			:textContent="bodyTable"
			:headers="headerTable"
			:download="false"
		/>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import qs from 'qs'
import axios from 'axios'

export default {
	components: {
		InfoTable: () => import('@/components/block/InfoTable'),
		NavTabs: () => import('@/components/block/NavTabs'),
		Ymap: () => import('@/components/block/geo/DeliveryMap.vue'),
	},
	data()
	{
		return {
			mapDisable: true,
			map: false,
			polygons: [],
			zones: [],
			navItems: [
				{
					name: 'Ресторан',
					target: '#infoTable_restaurant'
				},
				{
					name: 'География',
					target: '#infoTable_map'
				},
				{
					name: 'Реквизиты',
					target: '#infoTable_details'
				},
				{
					name: 'Контакты',
					target: '#infoTable_contacts'
				},
				{
					name: 'Договоры',
					target: '#infoTable_docs'
				},
			],
			headerTable: [
				{
					header: '#',
					size: 'infoTable__col_small'
				},
				{
					header: 'Вид договора',
					size: 'infoTable__col_large'
				},
				{
					header: 'С кем',
					size: 'infoTable__col_large'
				},
				{
					header: 'Номер договора',
					size: 'infoTable__col_middle'
				},
				{
					header: 'Дата начала',
					size: 'infoTable__col_middle'
				},
				{
					header: 'Дата окончания',
					size: 'infoTable__col_middle'
				},
			],
			restaurantHeaders: {
				'Название ресторана': 'NAME',
				'Адрес ресторана': 'ADDRESS',
				'Город': 'IBLOCK_SECTION_ID',
				'Код ресторана': 'CODE'
			},

			detailsHeaders: {
				'Юридическое название': 'YUR_NAME',
				'Юридический адрес': 'YUR_ADDRESS',
				'ОГРН': 'OGRN',
				'ИНН': 'INN',
				'Полное наименование банка': 'BANK',
				'Город банка': 'BANK_CITY',
				'БИК': 'BIK',
				'Корреспондентский счет': 'KORR_SCHET',
				'№ расчетного счета': 'RS'
			},
			contactsHeaders: {
				'ФИО контактного лица': 'FIO',
				'Контактный телефон (Мобильный)': 'PHONE',
				'Контактный телефон (Городской)': 'GOR_PHONE',
				'Телефон для уведомлений о заказе': 'PHONE_SMS',
				'Email': 'EMAIL',
				'Email для счетов': 'EMAIL_BILS',
				'Email для актов сверки': 'EMAIL_ACTS',
				'Email для статуса "Оплачен"': 'EMAIL_P',
				'Email для статуса "Собирается"': 'EMAIL_I',
				'Email для статуса "Собран"': 'EMAIL_C',
				'Email для статуса "Отменен"': 'EMAIL_O',
				'Email для статуса "Доставлен"': 'EMAIL_F',
				'Email для статуса "У курьера"': 'EMAIL_K'
			},
		}
	},
	computed: {
		...mapState('restaurant', ['restaurantDetail']),
		...mapState('map', ['fullZones']),
		...mapState(['currentOwnerRestaurant']),
		access()
		{
			return this.$store.state.user.IS_RST_OWNER || this.$store.state.user.IS_RST_MANAGER;
		},
		restaurantTable()
		{
			return this.fillHeaders(this.restaurantHeaders)
		},
		detailsTable()
		{
			return this.fillHeaders(this.detailsHeaders)
		},
		contactsTable()
		{
			return this.fillHeaders(this.contactsHeaders)
		},
		bodyTable()
		{
			let i = 1
			let res = []
			for (let num in this.restaurantDetail.CONTRACTS)
			{
				let contract = this.restaurantDetail.CONTRACTS[num];
				if (contract.NAME != '')
					res.push({
						body: [
							{
								content: i,
								size: 'infoTable__col_small'
							},
							{
								content: contract.TYPE,
								size: 'infoTable__col_large'
							},
							{
								content: contract.PARTNER,
								size: 'infoTable__col_large'
							},
							{
								content: contract.NAME,
								size: 'infoTable__col_middle'
							},
							{
								content: contract.DATE,
								size: 'infoTable__col_middle'
							},
							{
								content: '',
								size: 'infoTable__col_middle'
							}
						]
					})
				i += 1
			}
			return res;
		}
	},
	methods: {
		...mapActions('restaurant', ['loadRestaurantDetail']),
		...mapActions('map', ['getZones', 'drawZones', 'drawPlacemark']),
		async updateProperties(newVal, headers)
		{
			let res = [];
			for (let num in newVal)
			{
				let val = newVal[num]
				res.push({
					[headers[val.header]]: val.body
				})
			}
			let data = qs.stringify({
				'properties': res,
				'currentOwnerRestaurant': this.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/updateProperties/', data);
		},
		fillHeaders(headers)
		{
			let res = [];
			for (let header in headers)
			{
				res.push({
					header: header,
					body: this.restaurantDetail[headers[header]]
				})
			}
			return res;
		},
		isActive(item)
		{
			return this.activeItem === item;
		},
		setActive(item)
		{
			this.activeItem = item;
		},
		scrollToTable(event)
		{
			const table = document.querySelector(event.target.dataset.target);

			if (table)
			{
				table.scrollIntoView({block: "center", behavior: 'smooth'});
			}
		},
	},
	mounted()
	{
		if (!this.access) return;
		if (this.$store.state.currentCity === false)
		{
			this.$store.dispatch('getCurrentCity');
		}
		this.loadRestaurantDetail();
	},
	watch: {
		async map()
		{
			await this.getZones();
			const arr = this.restaurantDetail.ZONE
			this.zones = this.fullZones.filter(function (el)
			{
				return arr.includes(el.UF_XML_ID)
			});
			this.drawZones(this);
		}
	}
}

</script>

<style
	lang="scss"
	module
>
$gray: #979797;
$black: #3D4248;
$lightgray: #F0F0F0;
$green: #40AE49;

.noAccess
{
	color: red;
	margin: 20px;
	font-size: 22px;
}

.mapDisable
{
	pointer-events: none;
}

.container
{
	padding: 40px 72px;

	@media (max-width: 990px)
	{
		padding: 40px 10px;
	}
}

.navbar
{
	list-style: none;
	display: flex;
	margin-bottom: 44px;
	position: relative;

	&:after
	{
		content: '';
		position: absolute;
		display: block;
		z-index: 100;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		border-bottom: 2px solid $lightgray;
	}

	&__item
	{
		font-size: 16px;
		font-weight: 700;
		color: $black;
		cursor: pointer;
		padding-bottom: 10px;
		z-index: 200;

		&:not(:last-child)
		{
			margin-right: 24px;
		}

		&_active
		{
			color: $green;
			border-bottom: 2px solid;
		}
	}
}

.mapBlock
{
	margin-bottom: 56px;
	display: flex;
	align-items: center;
	flex-direction: column;


	&__title
	{
		color: $black;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 24px;
		align-self: flex-start;
	}

	&__body
	{
		border-radius: 8px;
		overflow: hidden;
		border: 2px solid $lightgray;
		width: 85%;
	}
}
</style>
